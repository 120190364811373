import React from 'react'
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { selectedTabSmallDev, toggleLeft } from '../store/switcher-slice';


export const useHeader = () => {

    const dispatch = useAppDispatch();
    const tabSelectedNumber = useAppSelector((state) => state.ui.selectedTabSideNavMenu)
    const showNavBar = useAppSelector((state) => state.ui.isVisibleLeftSideMenu)
    const leftMenuVisibilitySmallDevices = useAppSelector((state) => state.ui.isVisibleLeftSideMenuAux)

    const smallDevTabSelectionHandler = (selectedSection: string) => {
        dispatch(selectedTabSmallDev(selectedSection))
    }
    const switcherInitalState = () => {
        dispatch(toggleLeft())
    }

    return {
        tabSelectedNumber,
        smallDevTabSelectionHandler,
        showNavBar,
        leftMenuVisibilitySmallDevices,
        switcherInitalState
        
    }
}
