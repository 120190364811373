import React from "react";
import {
  Header,
  HeaderContainer,
  HeaderName,
  HeaderMenuButton,
  SkipToContent,
  SideNav,
  SideNavItems,
  SideNavMenuItem,
} from "carbon-components-react";
import { useHeader } from "../../customHooks/useHeader";

const HeaderComp = () => {

  const { smallDevTabSelectionHandler, showNavBar, leftMenuVisibilitySmallDevices, switcherInitalState } = useHeader()

  const tabSelectionHandler = (sectionNumber: string) => {
    smallDevTabSelectionHandler(sectionNumber)
    switcherInitalState()
    console.log(sectionNumber + " tabSelectedNumber!!!!!!!")
  }

  const visibilityHandler = () => {
    switcherInitalState()
  }

  return (
    <div id="smallScreenMenu">
      <HeaderContainer

        render={({ }) => (
          <>
            <Header aria-label="IkigaiSoft">
              <SkipToContent />


              <HeaderMenuButton
                aria-label="Open Primary menu"
                isCollapsible
                onClick={visibilityHandler}
                isActive={leftMenuVisibilitySmallDevices}
              />


              <HeaderName href="/" prefix="IKIGAI Inc">
              </HeaderName>

              {leftMenuVisibilitySmallDevices &&

                (
                  console.log("hi"),
                  <SideNav
                    aria-label="Side navigation"
                    isPersistent={true}
                    expanded={leftMenuVisibilitySmallDevices}
                  >
                    <SideNavItems>
                      <SideNavMenuItem onClick={() => tabSelectionHandler("0")} href="#">
                        About
                      </SideNavMenuItem>
                      <SideNavMenuItem onClick={() => tabSelectionHandler("1")} href="#">
                        Services
                      </SideNavMenuItem>
                      <SideNavMenuItem onClick={() => tabSelectionHandler("2")} href="#">
                        Contact
                      </SideNavMenuItem>
                    </SideNavItems>
                  </SideNav>)}

            </Header>
          </>
        )}

      />
    </div>

  )

};

export default HeaderComp;
function switcherInitalState() {
  throw new Error("Function not implemented.");
}

