import React from 'react';
import './app.scss';
import { Content } from 'carbon-components-react/lib/components/UIShell';
import { BrowserRouter, Route } from 'react-router-dom';
import LandingPage from './content/landingCoponent';
import HeaderComp from './components/headerComponent';
import { useHeader } from './customHooks/useHeader';

// import RepoPage from './content/RepoPage';

// This project if base in react carbon for ikigai web site

function App() {

  

  return (
    <>
     <HeaderComp  />
      <Content>
        <BrowserRouter>
          {/* <Routes> */}
          <Route path="/" component={LandingPage} />

          {/* </Routes> */}
        </BrowserRouter>
      </Content>

    </>
  );
}

export default App;
