import React, { useEffect, useState } from 'react';
import {
  Tab,
  Tabs,
  Button,

} from 'carbon-components-react';
import { InfoSection, InfoCard } from '../../components/Info';
import PersonFavorite32 from '@carbon/icons-react/lib/person--favorite/32';
import Application32 from '@carbon/icons-react/lib/application/32';
import { FormComp } from '../../components/Form/FormComp';
import backImage from "../../assets/Images/ikigai-bg.jpg";
import { useHeader } from '../../customHooks/useHeader';

const props = {
  tabs: {
    selected: 0,
    triggerHref: '#',
    role: 'navigation',
  },
  tab: {
    href: '#',
    role: 'presentation',
    tabIndex: 0,
  },
};



const LandingPage = () => {

  const { tabSelectedNumber } = useHeader()
  // Este state debe recibir el valor enviado desde el sidenavmenu
  const [selectedTab, setSelectedTab] = useState("");
  

  useEffect(() => {
    setSelectedTab(tabSelectedNumber);
    console.log(selectedTab)
  }, []);

  return (
    <>
      <div style={{ background: "black" }} className="bx--grid bx--grid--full-width landing-page marginLandingSmallDev">

        <div style={{ backgroundImage: `url(${backImage})`, backgroundPosition: "top center" }} className="bx--row landing-page__banner">
          <div className="bx--col-lg-16">
            <h1 className="landing-page__heading">
              IKIGAI Inc
            </h1>
          </div>
        </div>

        <div className="bx--row landing-page__r3" >
          <div className="bx--col bx--no-gutter">
            <Tabs id="smallScreenMenuLanding" {...props.tabs} aria-label="Tab navigation" selected={Number(tabSelectedNumber)} >

              <Tab {...props.tab} label="About">
                <div className="bx--grid bx--grid--no-gutter bx--grid--full-width">
                  <div className="bx--row landing-page__tab-content">
                    <div className="bx--col-md-4 bx--col-lg-7 marginTabSmallScreen">

                      <h2 className="landing-page__subheading">Who we are?</h2>
                      <p className="landing-page__p">
                        Ikigai was born out of an opportunity to be able to enhance the GRC platform experience through
                        knowledge and understanding gathered by its founder after having worked in the field for over 15 years.
                        <br /><br />
                        While there is no one literal translation for the Japanese word ‘Ikigai’ in the English language,
                        it could range from meaning, ‘the reason you wake up in the morning’, to anything that gives your
                        life purpose or meaning.<br /><br />

                        And that is how it quite literally was for the founder, from being the reason for waking up for
                        work in the morning during early days of his career, to evolving into his work's purpose becoming ‘
                        the enhancement of the GRC platform’. At Ikigai, we understand that ‘perfect’ can be an illusion,
                        but the pursuit of perfection on an everyday basis can inch us closer to the best that there is.
                      </p>
                      <Button>Learn more</Button>
                    </div>

                    {/* content for right column */}
                  </div>
                </div>
              </Tab>


              <Tab {...props.tab} label="Services">
                <div className="bx--grid bx--grid--no-gutter bx--grid--full-width">
                  <div className="bx--row landing-page__tab-content">
                    <div className="bx--col-md-4 bx--col-lg-7 marginTabSmallScreen">
                      <h2 className="landing-page__subheading">What we do?</h2><br/><br/>
                      <h2 className="landing-page__label"> Solution Planning and Design </h2>

                      <p className="landing-page__p">
                        Considerable time spent with your team to understand your business so as to propose the best
                        possible GRC solutions specific to your business model. Goals to be accomplished outlined based
                        on needs analysis. Proposal of possible approaches to achieve finalized objectives.
                        Identification of speculated road-blocks and work arounds.
                      </p>

                      <h2 className="landing-page__label">Implementation</h2>
                      <p className="landing-page__p">
                        Post finalization of objectives and approach, the following options are available for implementation:
                        You can sit back while our teams works on the implementation.
                        or
                        Our team of expert can guide your team with the implementation process.
                        or
                        An hybrid option where your team and ours take up partial implementation.
                      </p>

                      <h2 className="landing-page__label">Support </h2>
                      <p className="landing-page__p">
                        Ikigai prides itself for its consistency in ongoing support post implementation.
                      </p>

                    </div>
                  </div>
                </div>
              </Tab>

              <Tab {...props.tab} label="Contact">
                <div className="bx--grid bx--grid--no-gutter bx--grid--full-width">
                  <div className="bx--row landing-page__tab-content">
                    <div className="bx--col-md-4 bx--col-lg-7 marginTabSmallScreen">
                      <h2 className="landing-page__subheading">Get in Touch</h2>
                      <br />

                      <FormComp />
                    </div>

                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>

        <InfoSection heading="" className="bx--row landing-page__r3">

          <InfoCard
            heading="Email"
            body="info@ikigaisoft.com"
            icon={<PersonFavorite32 />}
          />


          <InfoCard
            heading="Address :"
            body="123 28th Ave NW, Calgary AB Canada"
            icon={<Application32 />}
          />
        </InfoSection>
        <br />


      </div>
      <div style={{ background: "black" }}>
        <p style={{ textAlign: "center", color: "white"  }}>&copy; <a href='http://www.ulogicapp.com'>2022 ikigaisoft.com</a></p>
      </div>
    </>
  );
};

export default LandingPage;
