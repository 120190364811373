import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./index";

// Define a type for the slice state
interface ToggleState {
  isVisibleLeftSideMenu: boolean;
  selectedTabSideNavMenu: string;
  isVisibleLeftSideMenuAux: boolean;
}

// Define the initial state using that type
const initialState: ToggleState = {
  isVisibleLeftSideMenu: false,
  selectedTabSideNavMenu: "",
  isVisibleLeftSideMenuAux: false
};

const sliceLeftMenu = createSlice({
  name: "ui",
  initialState,
  reducers: {
    selectedTabSmallDev(state, action) {
      state.selectedTabSideNavMenu = action.payload
    },
    toggleLeft(state) {
      state.isVisibleLeftSideMenuAux = !state.isVisibleLeftSideMenuAux;
    },
  },
});

export const { selectedTabSmallDev, toggleLeft } =
  sliceLeftMenu.actions;
export const selectUI = (state: RootState) => state.ui;

export default sliceLeftMenu.reducer;
